import React from "react"

import homePageStyle from "./youtubevideo.module.scss"

function YouTubeVideoAirCabTesting() {
  return (
    <div className={homePageStyle.videoContainer}>
      <iframe
        width="1280"
        height="720"
        src="https://www.youtube.com/embed/Sfsk6-h072Q"
        title="Environmental Enclosure Test Protocol | Cab Air Testing | 2 Minute Long"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  )
}

export default YouTubeVideoAirCabTesting
