import React from "react"

import homePageStyle from "./youtubevideo.module.scss"
import YouTubeVideoAirCabTesting from "./YouTubeVideoAirCabTesting"
import TestQuality from "../pages/resources/2025/Environmental Enclosure Test Protocol.pdf"
function YouTubeVideo() {
  return (
    <div className={homePageStyle.videoSection}>
      <h2 className={homePageStyle.videoTitle}>
        Environmental Enclosure Test Protocol
        <br /> Cab Air Testing - 2 Minute Video
      </h2>
      <p className={homePageStyle.videoParagraph}>
        <a
          className="download-link"
          target={"_blank"}
          rel="noreferrer"
          href={TestQuality}
        >
          Environmental Enclosure Test Protocol
        </a>
      </p>
      <YouTubeVideoAirCabTesting />
    </div>
  )
}

export default YouTubeVideo
